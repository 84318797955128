@use 'sass:math';
@use 'sass:map';
@import '../../styles/_meta';
@import './states/condensed';

.wrap {
	display: block;
	&_502 {
		display: flex;
		min-height: 100vh;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}
}

.imgWrap {
	text-align: center;
	@include mb(24px);
}

.title {
	font-weight: 800;
	font-size: 30px;
	line-height: 1;
	text-align: center;
	@include mb(16px);
}

.desc {
	text-align: center;
	font-size: 14px;
	line-height: math.div(18, 14);
	color: #040406;
	opacity: 0.48;
	padding-left: 16px;
	padding-right: 16px;
}

.btns {
	text-align: center;
	@include mt(24px);
}

.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 32px;
	font-size: 16px;
	line-height: 16px;
	font-weight: 500;
	height: 48px;
	color: #fff;
	background-color: #040406eb;
	padding-left: 24px;
	padding-right: 24px;
	@include hover('hover') {
		&:hover {
			background-color: rgba(4, 4, 6, 0.8);
		}
	}
}

.wrap_adapt_default {
	@include breakpoint(null, 'medium') {
		@include state_condensed();
	}
}
