@use 'sass:map';
@import '../../styles/_meta';

.pageWrap {
	display: block;
}

.error404 {
	position: relative;
	@include breakpoint('x-large') {
		z-index: 1000;
		top: -70px;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}
}
